<template>
    <div class="top-banner"><!--首页轮播-->
      <div class="Choices_banner">
        <ul ref="bannerList">
          <li v-for="(item, index) in bannerItems" :key="index">
            <img :src="BASE_URL+item" :alt="item.alt" style="width: 100%; height: 100%;">
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import { getCommonInfo} from "../../js/api/index";
export default {
  name: 'DTbanner',
  data(){
    return{
      BASE_URL:BASE_URL,
      bannerItems: [],
      timer2: null,
    }
  },
  methods: {
    animateBanner() {
      let bannerList = this.$refs.bannerList;
      let firstItem = bannerList.children[0];
      let marginLeft = -100;
      let transitionDuration = 2000;
      bannerList.style.transition = `margin-left ${transitionDuration}ms ease`;
      bannerList.style.marginLeft = `${marginLeft}%`;
      setTimeout(() => {
        bannerList.appendChild(firstItem);
        bannerList.style.transition = 'none';
        bannerList.style.marginLeft = '0';
        this.num += 100;

        if (this.num > 200) {
          this.num = 0;
        }
        firstItem = bannerList.children[0]; // 重新计算firstItem
      }, transitionDuration);
    },
     //初始化轮播图标
     initBannerItem(){
      getCommonInfo().then((res) => {
        let list = res.data.list[0];
        let imgList = list.topPictures.split(',');
        this.bannerItems = imgList;
      });
    },
  },
  created() {
    this.initBannerItem();
  },
  mounted() {
    this.timer2=setInterval(() => {
      this.animateBanner();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer2);
    this.timer2 = null;
  },
}
</script>
<style scoped lang="scss">
.top-banner {
  margin: 0px auto;
  background-color: rgb(233, 233, 233);
  padding: 0px;
  height: 360px;
  width: 1280px;
  overflow: visible;
  position: relative;
  .Choices_banner {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .Choices_banner ul {
    width: 400%;
    overflow: hidden;
    list-style: none;
    transition: margin-left 0.6s ease;
    height: 100%;
  }
  .Choices_banner li {
    height: 100%;
    float: left;
    width: 25%;
  }
}
</style>
